/* global angular:true, _:true */
import { loadGoogleMaps } from 'helioscope/app/utilities/maps';
import { loadTemplate } from 'helioscope/app/utilities/helpers';
import { user } from 'helioscope/app/users/auth';
import * as analytics from 'helioscope/app/utilities/analytics';

import { WEATHER_CONSTS } from 'reports/models/weather_source.ts';


(function (angular, _) {
    'use strict';

    var mod = angular.module('helioscope.libraries.controllers',
                             ['helioscope.services', 'ngGrid', 'angularFileUpload', 'ngMathJax', 'ui.map']);


    mod.controller('ModuleUploadCtrl', ['$scope', '$modalInstance', 'FileUploader', '$modal', 'ModuleCharacterization', '$filter', 'Messager', '$log',
                                        function ($scope, $modalInstance, FileUploader, $modal, ModuleCharacterization, $filter, messager, $log) {


            var _numberFilter = $filter('number'),
                notifyBadFile = _.debounce(function () {
                    messager.error("You can only upload .pan files");
                }, 500, true);



            function percentage(x) {return _numberFilter(x * 100, 1) + '%'; }


            $scope.close = function () {
                $modalInstance.close();
            };


            $scope.uploader = new FileUploader({
                url: "/api/module_characterizations/pan",
                autoUpload: true,
            });


            $scope.uploader.filters.push({
                name: 'PanFilesOnly',
                fn: function (item) {
                    var ext = item.name !== undefined ? item.name.split(".").pop() : "pan";

                    if (ext.toLowerCase() !== "pan") {
                        notifyBadFile();
                        return false;
                    }

                    return true;
                }
            });

            $scope.uploader.onSuccessItem = function (item, response) {
                var best = 0.95,
                    characterization;

                try {
                    characterization = angular.fromJson(response);
                    if (characterization === undefined) {
                        throw "Undefined";
                    }

                    if (characterization.error !== undefined) {
                        throw characterization.error;
                    }

                    item.module = characterization.module;
                    item.characterization = new ModuleCharacterization(characterization);
                    // Prevent item.characterization.module from getting clobbered
                    // when item.characterization.module_id changes (e.g. to "" or "n")
                    Object.defineProperty(item.characterization, 'module', { value: item.module });
                } catch (e) {
                    $log.warn("Invalid response: " + e);
                    item.isError = true;
                    item.isSuccess = false;
                    return;
                }


                if (typeof item.characterization.module_characterization_id === "number") {
                    // messager.success("Successfully uploaded Module Characterization from "+file.name);
                    return;
                }

                if (item.characterization.module_id === null) {
                    item.characterization.module_id = "";
                    angular.forEach(item.characterization.metadata.modules, function (m) {
                        if (m._match_quality >= best) {
                            item.characterization.module_id = m.module_id;
                            best = m._match_quality;
                        }
                        m._name_string = m.manufacturer + ", " + m.name + " (" + percentage(m._match_quality) + ")";
                    });

                    if (item.characterization.metadata.modules.length > 0) {
                        if (best < 1.0) {
                            // this option only makes sense if there's no perfect match;
                            // otherwise, when we click upload, the created module will conflict with
                            // the perfect match and cause an error (SQL unique constraint violation)
                            item.characterization.metadata.modules.push({
                                _name_string: 'Create a new module',
                                module_id: 'n',
                            });
                        }
                    } else {
                        item.characterization.module_id = "n";
                    }
                }
            };


            $scope.uploader.onBeforeUploadItem = function (item) {
                if (item.hasOwnProperty('characterization')) {

                    if (typeof item.characterization.module_characterization_id === 'number') {
                        // prevent because the file has already been uploaded
                        return false;
                    }

                    if (item.characterization.module_id === "" || item.characterization.module_id === null) {
                        messager.info("Select a module before uploading " + item.file.name);
                        return false;
                    }

                    item.formData = [{
                        characterization: angular.toJson(item.characterization)
                    }];
                }
            };

            $scope.saveAll = function () {
                angular.forEach($scope.uploader.queue, function (item) {
                    if (!item.isError) {
                        item.upload();
                    }
                });
            };


            $scope.previewCharacterization = function (item) {
                $modal.open({
                    windowClass: 'full-screen-modal',
                    controller: 'CharacterizationPreviewCtrl',
                    templateUrl: require('helioscope/app/libraries/partials/modules/upload.preview.html'),
                    resolve: {
                        characterization: function () {return item.characterization; }
                    },
                }).result.then(function (exit) {
                    if (exit === 'save') {
                        item.upload();
                    }
                });
            };

        }]);

    mod.controller("CharacterizationPreviewCtrl", ['$scope', 'characterization', '$modalInstance',
                                                   function ($scope, characterization, $modalInstance) {

            var backup = angular.copy(characterization);
            $scope.characterization = characterization;

            $scope.$on('$destroy', function () {
                if ($scope.characterization.name === undefined || $scope.characterization.name === "") {
                    $scope.characterization.name = backup.name;
                }
            });

            $scope.close = function () {
                $modalInstance.close();
            };

            $scope.save = function () {
                $modalInstance.close('save');
            };

            $scope.undo = function () {
                angular.extend($scope.characterization, backup);
            };

            function getModule() {
              // if(characterization.module_id)
                if (characterization.module_id === "n") {
                    return characterization.module;
                }
                return _.find(characterization.metadata.modules,
                              function (m) {return m.module_id === characterization.module_id; });
            }

            $scope.module = getModule();

            $scope.$watch('characterization.module_id', function () {
                $scope.module = getModule();
            });


            $scope.conditions = {
                temperature: 25,
                irradiance: 1000,
                irradianceKeys: [1000, 800, 600, 400, 200, 100],
                temperatureKeys: [20, 25, 30, 35, 45, 55],
                graphType: 'current',
                dependence: 'irradiance'
            };
        }]);


    mod.controller("ModuleSearchCtrl", ['$scope', function ($scope) {
        $scope.currentUser = user;
        $scope.cellTechnologies = ['Si-Mono', 'Si-Poly', 'HIT', 'CIS', 'CdTe', 'uCSi-aSi:H'];
    }]);

    mod.controller("ModuleCharacterizationListCtrl", ['$scope', function ($scope) {
        $scope.module = $scope.item;

        $scope.bifacialEnabled = user.hasFeature('enable_bifacial');

        //putting conditions here makes them persistent across different modules
        $scope.conditions = {
            temperature: 25,
            irradiance: 1000,
            irradianceKeys: [1000, 800, 600, 400, 200, 100],
            temperatureKeys: [20, 25, 30, 35, 45, 55],
            graphType: 'current',
            dependence: 'irradiance'
        };
    }]);

    mod.controller("ModuleCharacterizationDetailCtrl", ['$scope', '$modal', function ($scope, $modal) {

        var impl = $scope.characterization.implementation(1000, 25),
            characterizationDocs = {
                'full_diode': require('helioscope/app/documentation/partials/formulation/modules.single_diode.html'),
                'pvsyst': require('helioscope/app/documentation/partials/formulation/modules.pvsyst.html'),
            };

        $scope.$watch('conditions', function () {
            var conditionTuples;
            $scope.conditions.temperature *= 1;
            $scope.conditions.irradiance *= 1;
            if ($scope.conditions.dependence === "irradiance") {
                conditionTuples =  _.map($scope.conditions.irradianceKeys,
                                         function (i) {return {irradiance: i, temperature: $scope.conditions.temperature}; });
            } else {
                conditionTuples =  _.map($scope.conditions.temperatureKeys, function (t) {return {irradiance: $scope.conditions.irradiance, temperature: t}; });
            }

            angular.forEach(conditionTuples, function (c) {
                impl.setConditions(c.irradiance, c.temperature);

                c.vOc = impl.vOc();
                c.iSc = impl.iSc();

                var powerTuple = impl.maxPower([0, c.vOc]),
                    tempDelta = 0.01,
                    powerTupleDT;

                c.iMp = powerTuple.iMp;
                c.vMp = powerTuple.vMp;
                c.pMp = powerTuple.iMp * powerTuple.vMp;

                impl.setConditions(c.irradiance, c.temperature + tempDelta);
                powerTupleDT = impl.maxPower([c.vMp - 1, c.vMp + 1]);


                c.dVdT = (powerTupleDT.vMp - c.vMp) / tempDelta  / c.vMp * 100;
                c.dPdT = (powerTupleDT.vMp * powerTupleDT.iMp - c.pMp) / tempDelta / c.pMp * 100;
                c.dVocdT = (impl.vOc() - c.vOc) / tempDelta / c.vOc  * 100;
            });

            $scope.conditionTable = conditionTuples;

        }, true);

        $scope.openDocumentation = function () {
            $modal.open({
                windowClass: 'documentation-modal',
                controller: 'CharacterizationDocumentationCtrl',
                templateUrl: characterizationDocs[$scope.characterization.module_model_name],
            });
        };
    }]);


    mod.controller("DeviceSearchCtrl", ['$scope', function ($scope) {
        $scope.currentUser = user;
        $scope.checkboxFilters = {
            'optimizers': true,
            'inverters': true,
        };

        $scope.updateCheckboxes = function () {
            var checks = $scope.checkboxFilters;
            if (checks.optimizers && checks.inverters) {
                $scope.search.device_type = "";
            } else if (checks.inverters) {
                $scope.search.device_type = "inverter";
            } else if (checks.optimizers) {
                $scope.search.device_type = "optimizer";
            }
        };

    }]);

    mod.controller("DeviceCharacterizationListCtrl", ['$scope', function ($scope) {
        $scope.device = $scope.item;
    }]);

    mod.controller("DeviceCharacterizationDetailCtrl", ['$scope', function ($scope) {
        const points = $scope.characterization.efficiency_points;
        $scope.factor_1 = _(points).map('factor_1').uniq().value().sort((a, b) => { return a - b; });
        $scope.factor_2 = _(points).map('factor_2').uniq().value().sort((a, b) => { return a - b; });

        $scope.efficiency_perc = function (f1, f2) {
            const point = _(points).find({ factor_1: f1, factor_2: f2 });
            // not every combination of factor_1 and factor_2 exists
            if (!point) {
                return '-';
            }
            // turn 0.911 into '91.1%'
            const eff = point.efficiency * 100;
            return `${eff.toFixed(1)}%`;
        };
    }]);

    mod.controller("CharacterizationDocumentationCtrl", ['MathJax', '$timeout', function (MathJax, $timeout) {
        $timeout(MathJax.typeset);
    }]);


    mod.controller("MeteoCtrl", ['$scope', 'WeatherSource', 'Messager', 'google', 'MarkerClusterer', 'prospectorBounds', 'psmBounds', '$state', 'WeatherDataset', '$q',
                                 function ($scope, WeatherSource, messager, google, MarkerClusterer, prospectorBounds, psmBounds, $state, WeatherDataset, $q) {

            var notify = messager.load("Loading Weather Data"),
                mapDeferred = $q.defer(),
                once = angular.noop,
                icons = {
                    'tmy2': { color: 'red', image: require('helioscope/app/libraries/static/icons/sunny-red.png') },
                    'tmy3': { color: 'orange', image: require('helioscope/app/libraries/static/icons/sunny-orange.png') },
                    'epw': { color: 'yellow', image: require('helioscope/app/libraries/static/icons/sunny-yellow.png') },
                    'other': { color: 'green', image: require('helioscope/app/libraries/static/icons/sunny-green.png') },
                    'custom': { color: 'blue', image: require('helioscope/app/libraries/static/icons/sunny-blue.png') },
                    'prospector': { color: 'yellow', image: require('helioscope/app/libraries/static/icons/sunny-yellow.png') },
                    'psm3': { color: '#b50eb5', image: require('helioscope/app/libraries/static/icons/sunny-yellow.png') },
                    'gray': { color: 'gray', image: require('helioscope/app/libraries/static/icons/sunny-gray.png') },
                },
                allowedTypes = _.keys(icons);

            $scope.$on('$destroy', function () {
                if ($scope.prospectorPolygon) {
                    $scope.prospectorPolygon.setMap(null);
                    google.maps.event.clearListeners($scope.prospectorPolygon);
                }

                if ($scope.psmPolygon) {
                    $scope.psmPolygon.setMap(null);
                    google.maps.event.clearListeners($scope.psmPolygon);
                }

                angular.forEach($scope.data.markers, function (marker) {
                    marker.setMap(null);
                });

                WeatherSource.clear();
                WeatherDataset.clear();

            });

            $scope.groupedSources = {};
            $scope.data = {};
            $scope.legend = {
                visible: {
                    tmy2: true,
                    tmy3: true,
                    epw: true,
                    prospector: true,
                    psm3: true,
                    custom: true,
                    other: true,
                },
                icons: angular.copy(icons)
            };

            $scope.trackDownload = () => {
                analytics.track('weather.download', {
                    weather_source_id: $scope.data.selectedWeather.weather_source_id,
                });
            };

            $scope.toggleMarkers = function (sourceType) {
                $scope.legend.visible[sourceType] = !$scope.legend.visible[sourceType];

                var visible = $scope.legend.visible[sourceType],
                    map = visible ? $scope.data.map : null,
                    markers = $scope.groupedSources[sourceType];

                $scope.legend.icons[sourceType] = visible ? icons[sourceType] : icons['gray'];

                if (sourceType === 'prospector') {
                    $scope.prospectorPolygon.setMap(map);
                } else if (sourceType === 'psm3') {
                    $scope.psmPolygon.setMap(map);
                }

                if (markers === undefined) {
                    return;
                }

                if (visible) {
                    $scope.data.cluster.addMarkers(markers);
                } else {
                    $scope.data.cluster.removeMarkers(markers);
                }

            };

            function weatherGroup(source_type) {
                if (allowedTypes.indexOf(source_type) === -1) {
                    return 'other';
                }
                return source_type;

            }

            $scope.data.markers = [];
            $scope.mapReady = mapDeferred.promise;
            $scope.mapOptions = {
                center: new google.maps.LatLng(0, 0),
                zoom: 2,
                maxZoom: 12,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                disableDoubleClickZoom: true,
                draggableCursor: 'default',
                tilt: 0,
                clickableIcons: false,
                streetViewControl: false,
            };

            once = $scope.$watch('data.map', function (m) {
                mapDeferred.resolve(m);
                once();
            });

            WeatherSource.query({limit: 10000},
                function (sources) {

                    notify.progress('Loaded ' + sources.length + ' Meteo Files');
                    $scope.data.weatherSources = sources;
                    angular.forEach(sources, function (s) {
                        s.jitter(0.1);
                        var group = weatherGroup(s.source_type),
                            marker = new google.maps.Marker({
                                position: s.location.googleLatLng(),
                                title: s.toString(),
                                icon: icons[group].image,
                            });

                        $scope.data.markers.push(marker);


                        if ($scope.groupedSources[group] === undefined) {
                            $scope.groupedSources[group] = [marker];
                        } else {
                            $scope.groupedSources[group].push(marker);
                        }

                    });

                    $scope.data.cluster = new MarkerClusterer($scope.data.map, $scope.data.markers, {
                        'gridSize': 40,
                        imagePath: 'https://raw.githubusercontent.com/googlearchive/js-marker-clusterer/' +
                        'gh-pages/images/m',
                    });
                    notify.success('Loaded ' + sources.length + ' Meteo Files');

                }, function () {
                    notify.error('Could not load weather data');
                });



            $scope.selectWeather = function (index) {
                $state.go('library.meteo.detail', {weather_source_id: $scope.data.weatherSources[index].weather_source_id,
                                                   lat: $scope.data.markers[index].position.lat(), lng: $scope.data.markers[index].position.lng()});

            };


            function prospectorRounder(num) {
                // round to increments of 0.1, ending in 0.05
                return Math.round((Math.trunc(num * 10.0) / 10.0 + (num > 0 ? 0.05 : -0.05)) * 100) / 100;
            }

            function prospectorAddress(latLng) {
                return {
                    lat: prospectorRounder(latLng.lat()),
                    lng: prospectorRounder(latLng.lng()),
                    weather_source_id: 'prospector'
                };
            }

            function psmAddress(latLng) {
                return {
                    lat: Math.round(
                        (latLng.lat() - WEATHER_CONSTS.PsmLatZero) / WEATHER_CONSTS.PsmRes) * WEATHER_CONSTS.PsmRes +
                        WEATHER_CONSTS.PsmLatZero,
                    lng: Math.round(
                        (latLng.lng() - WEATHER_CONSTS.PsmLonZero) / WEATHER_CONSTS.PsmRes) * WEATHER_CONSTS.PsmRes +
                        WEATHER_CONSTS.PsmLonZero,
                    weather_source_id: 'psm3',
                };
            }

            $scope.drawPolygons = function () {
                if (!$scope.prospectorPolygon) {
                    $scope.prospectorPolygon = new google.maps.Polygon({
                        map: $scope.data.map,
                        fillColor: icons.prospector.color,
                        strokeColor: icons.prospector.color,
                        fillOpacity: 0.1,
                        strokeOpacity: 0.5,
                        paths: prospectorBounds,
                    });
                    google.maps.event.addListener($scope.prospectorPolygon, 'click', function (evt) {
                        $state.go('library.meteo.detail', prospectorAddress(evt.latLng));
                    });
                }

                if (!$scope.psmPolygon) {
                    $scope.psmPolygon = new google.maps.Polygon({
                        map: $scope.data.map,
                        fillColor: icons.psm3.color,
                        strokeColor: icons.psm3.color,
                        fillOpacity: 0.1,
                        strokeOpacity: 0.5,
                        paths: psmBounds,
                    });
                    google.maps.event.addListener($scope.psmPolygon, 'click', function (evt) {
                        $state.go('library.meteo.detail', psmAddress(evt.latLng));
                    });
                }
            };
        }]);

    mod.controller("MeteoDetailCtrl", ['$scope', 'TemplatedInfoWindow', 'weather_source', 'google', '$stateParams', function ($scope, templatedInfoWindow, weather_source, google, $stateParams) {
        var currentWeatherMarker,
            infoWindow;

        $scope.$on('$destroy', function () {
            if (currentWeatherMarker) {
                currentWeatherMarker.setMap(null);
            }

            if (infoWindow) {
                infoWindow.setMap(null);
            }
        });

        $scope.data.selectedWeather = weather_source;

        function getLocation() {
            if ($stateParams.lat && $stateParams.lng) {
                return new google.maps.LatLng($stateParams.lat, $stateParams.lng);
            }

            return $scope.data.selectedWeather.location.googleLatLng();

        }

        $scope.mapReady.then(function (map) {

            if (currentWeatherMarker === undefined) {
                currentWeatherMarker = new google.maps.Marker({
                    map: map,
                    icon: require('helioscope/app/libraries/static/icons/flag.png'),
                    position: getLocation(),
                    zIndex: 999
                });
            } else {
                currentWeatherMarker.setPosition(getLocation());
            }

            templatedInfoWindow(require('helioscope/app/libraries/partials/meteo/infowindow.html'), $scope).then(
                function (iw) {
                    infoWindow = iw;
                    infoWindow.open(map, currentWeatherMarker);
                    analytics.track('weather.inspect', {
                        weather_source_id: $scope.data.selectedWeather.weather_source_id,
                    });
                }
            );
        });

    }]);

    mod.factory("TemplatedInfoWindow", ['$compile', '$q', function ($compile, $q) {
        return function (templateUrl, $scope) {
            var deferred = $q.defer();

            $q.all([loadGoogleMaps(), loadTemplate(templateUrl)]).then(function (promiseResults) {
                var google = promiseResults[0],
                    html = promiseResults[1],
                    compiled = $compile(html)($scope);
                deferred.resolve(new google.maps.InfoWindow({
                    content: compiled[0],
                    maxWidth: 300
                }));
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        };
    }]);


    mod.controller('MeteoUploadCtrl', ['$scope', '$modalInstance', 'FileUploader', '$log', 'WeatherSource', '$modal',
                                        function ($scope, $modalInstance, FileUploader, $log, WeatherSource, $modal) {
            $log.log("Meteo Upload Controller");

            $scope.filetypes = {
                'csv': "TMY3 (.csv)",
                'tm3': "TMY3 (.tm3)",
                'tm2': "TMY2 (.tm2)",
                'epw': "EnergyPlus Worldwide (.epw)",
                "csv-prospector": "Prospector (.csv)",
                "gz": "Compressed Prospector (.csv.gz)",
                "zip": "EPW, Embedded in Zip (.zip)"
            };

            $scope.close = function () {
                $modalInstance.close();
            };

            $scope.uploader = new FileUploader({
                url: "/api/weather_sources/upload",
                autoUpload: false
            });

            $scope.uploader.onAfterAddingFile = function (item) {
                item.filetype = item.file.name !== undefined ? item.file.name.split(".").pop() : "csv";
            };


            $scope.uploader.onBeforeUploadItem = function (item) {
                item.formData = [{
                    filetype: `"${item.filetype}"`,
                }];
            };

            $scope.uploader.onSuccessItem = function (item, response) {
                var weatherSource;

                try {
                    weatherSource = angular.fromJson(response);
                    if (weatherSource === undefined) {
                        throw "Undefined";
                    }

                    if (weatherSource.error !== undefined) {
                        throw weatherSource.error;
                    }

                    item.weather_source = new WeatherSource(weatherSource);

                    analytics.track('weather.upload', {
                        weather_source_id: item.weather_source.weather_source_id,
                    });
                } catch (e) {
                    $log.warn("Invalid response: " + e);
                    item.isError = true;
                    item.isSuccess = false;
                    return;
                }

            };

            $scope.saveAll = function () {
                angular.forEach($scope.uploader.queue, function (item) {
                    if (!item.isError) {
                        item.upload();
                    }
                });
            };

            $scope.editWeatherSource = function (item) {
                $modal.open({
                    controller: 'ModifyWeatherCtrl',
                    templateUrl: require('helioscope/app/libraries/partials/meteo/modify.html'),
                    resolve: {
                        weather_source: function () {return item.weather_source; }
                    },
                });
            };

        }]);

    mod.controller('ModifyWeatherCtrl', ['$scope', '$modalInstance', '$log', 'weather_source', 'Messager',
                                       function ($scope, $modalInstance, $log, weather_source, messager) {
            $log.log("Meteo Upload Controller");
            $scope.weatherSource = angular.copy(weather_source);
            $scope.weatherSource.dataset_name = weather_source.weather_datasets[0].name;

            $scope.save = function () {
                var backup = angular.copy(weather_source);
                $scope.isLoading = true;
                delete $scope.errors;
                angular.extend(weather_source, $scope.weatherSource);

                weather_source.$update(function () {
                    messager.success("Changes saved successfully.");
                    $scope.isLoading = false;
                }, function (response) {
                    $log.warn(response);
                    messager.error("Changes could not be saved");
                    $scope.isLoading = false;
                    angular.extend(weather_source, backup);
                    $scope.errors = response.data;
                });
            };

            $scope.cancel = function () {
                $modalInstance.close();
            };

        }]);
}(angular, _));

import moment from 'moment';
import { isEmpty, clamp, get } from 'lodash';
import { $rootScope } from 'helioscope/app/utilities/ng';
import { User } from 'helioscope/app/users';
import * as analytics from 'helioscope/app/utilities/analytics';
import { getBetaBillingURL, getAddProjectModalURL, getBetaSignupURL } from 'helioscope/app/utilities/url';
import { convertColorAndOpacity } from 'helioscope/app/utilities/colors';

import bcCountries from 'bc-countries';

import { Team } from './users/teams';

const mod = angular.module('helioscope.controllers', ['helioscope.projects.services', 'ng-help', 'bcPhoneNumber']);
mod.controller('HelioscopeCtrl', [
    '$rootScope',
    'Authenticator',
    'ContextualHelp',
    '$window',
    function ($rootScope, Authenticator, ContextualHelp, $window) {
        $rootScope.logout = Authenticator.logout;
        $rootScope.user = Authenticator.user;
        $rootScope.promptLogin = Authenticator.promptLogin;
        $rootScope.authorized = Authenticator.authorized;
        $rootScope.hideHeader = false;

        $rootScope._scrollTop = () => {
            $window.scrollTo(0, 0);
        };

        $rootScope.ContextualHelp = ContextualHelp;
        ContextualHelp.addStateListener();
    },
]);

mod.controller('FooterCtrl', [
    '$scope',
    ($scope) => {
        $scope.year = new Date().getFullYear();
    },
]);

mod.controller('HomeCtrl', [
    '$scope',
    'newProjectDlg',
    'Authenticator',
    '$state',
    '$stateParams',
    ($scope, newProjectDlg, Authenticator, $state, $stateParams) => {
        $scope.newProject = () => newProjectDlg.openDialog($scope);

        $scope.login = () => {
            Authenticator.promptLogin();
        };

        $scope.isUnpaidPayByInvoice =
            Authenticator.user().latest_subscription &&
            Authenticator.user().latest_subscription.cleaned_status === 'unpaid' &&
            Authenticator.user().latest_subscription.is_pay_by_invoice;
        if (Authenticator.user().user_id && Authenticator.user().isExpired() && !$scope.isUnpaidPayByInvoice) {
            $state.go('home.expired');
        }

        if ($stateParams.login !== undefined && !Authenticator.user().user_id) {
            $scope.login();
        }

        $scope.betaBillingURL = getBetaBillingURL(Authenticator.user());
    },
]);

class ProjectCountCalloutCtrl {
    constructor($scope) {
        'ngInject';

        this.$scope = $scope;
        this.betaBillingURL = getBetaBillingURL($scope.user());
    }

    async init() {
        this.showCallout = false;
        this.projectsRemaining = 0;
        if (this.$scope.user().status === 'trial') {
            const teamLimits = await Team.limitsAndUsage({ team_id: this.$scope.user().team_id }).$promise;
            // trial project_limit can be null, which indicates that the user has
            // unlimited projects available.
            const trialLimits = teamLimits.trial_limits;
            this.showCallout = !!(trialLimits && trialLimits.project_limit);
            if (this.showCallout) {
                const projectsRemaining = trialLimits.project_limit - trialLimits.project_count;
                this.projectsRemaining = projectsRemaining > 0 ? projectsRemaining : 0;
            }
        }
    }
}

mod.controller('ProjectCountCalloutCtrl', ProjectCountCalloutCtrl);

class ProjectUsageCtrl {
    PROGRESS_BAR_STATUS_COLORS = {
        default: '#60c4f4',
        warning: '#FF9900',
        critical: '#AC2F33',
    };

    PROGRESS_BAR_BACKGROUND_OPACITY = 0.25;
    PROJECT_LIMIT_CRITICAL_PERCENT = 80;

    // eslint-disable-next-line
    constructor($scope, $rootScope) {
        'ngInject';

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.progress = 100;
        this.betaBillingURL = getBetaBillingURL($scope.user(), '', {});
        this.addProjectModalUrl = getAddProjectModalURL();
    }

    getMeterColor() {
        if (!this.teamLimits || !this.teamLimits.project_count || !this.teamLimits.project_limit) {
            return this.PROGRESS_BAR_STATUS_COLORS.default;
        }

        const { project_count: projectCount, project_limit: projectLimit } = this.teamLimits;
        const usagePercentage = Math.round((projectCount / projectLimit) * 100);

        if (projectCount >= projectLimit) {
            return this.PROGRESS_BAR_STATUS_COLORS.critical;
        }

        if (usagePercentage >= 80) {
            return this.PROGRESS_BAR_STATUS_COLORS.warning;
        }

        return this.PROGRESS_BAR_STATUS_COLORS.default;
    }

    getProgressBarBackgroundColor() {
        return convertColorAndOpacity(this.getMeterColor(), this.PROGRESS_BAR_BACKGROUND_OPACITY);
    }

    getProgressBarPercentage() {
        return clamp((this.teamLimits.project_count / this.teamLimits.project_limit) * 100, 0, 100);
    }

    navigateToAddProjectsModal() {
        if (
            this.teamLimits &&
            this.teamLimits.project_count !== undefined &&
            this.teamLimits.project_limit !== undefined
        ) {
            // eslint-disable-next-line no-nested-ternary
            const subscriptionType = $rootScope.user().subscription
                ? $rootScope.user().subscription.plan_type === 'year'
                    ? 'annual'
                    : 'monthly'
                : null;
            analytics.track('paywall.consumption_enforcement.open', {
                projectCount: this.teamLimits.project_count,
                projectLimit: this.teamLimits.project_limit,
                referrer: 'add project',
                model_type: {
                    user_type: $rootScope.user().team_admin ? 'admin' : 'non-admin',
                    subscription_type: subscriptionType,
                },
            });
        }
    }

    async init() {
        this.showProgressBar = false;
        this.showAddProjectBtn = false;

        const isTrialUser = this.$scope.user().status === 'trial';

        if (!isTrialUser) {
            const teamLimitsAndUsage = await Team.limitsAndUsage({ team_id: this.$scope.user().team_id }).$promise;
            this.teamLimits = teamLimitsAndUsage.subscription_limits;
        }

        const isContractSubscription =
            get(this.$scope.user(), 'latest_subscription.subscription_type', false) === 'contract';
        if (isContractSubscription) {
            return;
        }
        const hasTeamLimits = this.teamLimits && !!this.teamLimits.project_limit;

        if (hasTeamLimits) {
            this.showProgressBar = true;
            this.progress = this.getProgressBarPercentage();
            this.progressBarBackgroundColor = this.getProgressBarBackgroundColor();
            this.meterColor = this.getMeterColor();
            this.showAddProjectBtn =
                this.$scope.user().team.should_upsell_consumption &&
                this.getProgressBarPercentage() >= this.PROJECT_LIMIT_CRITICAL_PERCENT;
        }
    }
}

mod.controller('ProjectUsageCtrl', ProjectUsageCtrl);

mod.controller('BillingRedirectModalCtrl', ($scope, $modalInstance, user) => {
    $scope.user = user;
    $scope.teamAdmin = !!user.team_admin;
    $scope.addProjectsUrl = getAddProjectModalURL();
    $scope.close = () => $modalInstance.close();
});


export class TrialLimitCalloutCtrl {
    // eslint-disable-next-line
    constructor($scope, $rootScope, $stateParams) {
        'ngInject';

        const user = $scope.user();
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.betaBillingURL = getBetaBillingURL(user);
    }

    init() {
        const user = this.$scope.user();
        // Sometimes after login, user === {}.  We need to bail early if that is the case.
        if (isEmpty(user)) {
            return;
        }

        this.computeState();

        // Hack to force a re-render on route change
        this.$rootScope.$on('$stateChangeStart', () => {
            this.computeState();
        });
    }

    async computeState() {
        const user = this.$scope.user();
        const subscription = user.subscription;

        this.activeSubscription = user.activeSubscription();
        this.hasExpiredSubscription = user.status === 'expired_subscription';

        this.paymentFail = subscription && subscription.has_failed_payment;

        if (!user.isExpired() && user.current_period_end) {
            this.timeLeft = user.current_period_end.fromNow().slice(3);
        } else {
            this.timeLeft = null;
        }
    }
}

const STRIPE_INVOICE_DIMENSIONS = 'width=485,height=730';
const paymentFailureCalloutCtrl = function ($scope, $window) {
    const user = $scope.user();
    const subscription = user.latest_subscription;

    $scope.canManageBilling = user.role.can_manage_billing;

    if (!subscription || !$scope.canManageBilling) {
        return;
    }

    $scope.hasPaymentFailure = subscription && subscription.has_failed_payment;
    $scope.isPayByInvoice = subscription.is_pay_by_invoice;
    $scope.interval = subscription.plan_type === 'year' ? 'annual' : 'monthly';
    const daysLeft = moment(subscription.current_period_start)
        .add(subscription.payment_failure_info.has_3DS_failure ? 15 : 14, 'days')
        .diff(subscription.now(), 'days');

    $scope.daysLeft = daysLeft <= 0 ? 0 : daysLeft;
    const latestInvoiceUrl = subscription.payment_failure_info.latest_invoice_url;
    $scope.openInvoice = function () {
        $window.open(latestInvoiceUrl, 'popup', STRIPE_INVOICE_DIMENSIONS);
    };
};

const unpaidPayByInvoiceCalloutCtrl = function ($scope, $window) {
    const user = $scope.user();
    const subscription = user.latest_subscription;

    $scope.canManageBilling = user.role.can_manage_billing;
    $scope.isUnpaidPayByInvoice = subscription && subscription.is_pay_by_invoice && subscription.status === 'unpaid';

    if (!subscription || !$scope.canManageBilling) {
        return;
    }

    const latestInvoiceUrl = subscription.payment_failure_info.latest_invoice_url;
    $scope.openInvoice = function () {
        $window.open(latestInvoiceUrl, 'popup', STRIPE_INVOICE_DIMENSIONS);
    };
};

mod.controller('TrialLimitCalloutCtrl', TrialLimitCalloutCtrl);
mod.controller('PaymentFailureCalloutCtrl', ['$scope', '$window', paymentFailureCalloutCtrl]);
mod.controller('UnpaidPayByInvoiceCalloutCtrl', ['$scope', '$window', unpaidPayByInvoiceCalloutCtrl]);

mod.controller('PricingCtrl', ['$scope', 'SignupDlg', function ($scope, signupDlg) {}]);

mod.controller('CustomLandingCtrl', [
    '$scope',
    'Messager',
    'User',
    '$state',
    '$log',
    'Authenticator',
    '$window',
    'userSource',
    function ($scope, Messager, User, $state, $log, Authenticator, $window, userSource) {
        $scope.newUser = new User(User.computeLocalizationProps());

        $scope.checkPasswords = function () {
            return $scope.newUser.password === $scope.newUser.confirm_password;
        };

        $scope.createUser = function () {
            delete $scope.errors;
            $scope.denied = false;
            $scope.isLoading = true;

            const notification = Messager.load('Creating new account');

            $scope.newUser.$save(
                { source: userSource },
                (user) => {
                    Authenticator.loginUser(user);
                    notification.success('Successfully created your account');
                    analytics.trackConversion(user, analytics.conversionLabels.accountCreatedCustomPage);
                    analytics.track('New User Signup', {
                        user_id: user.user_id,
                        user_email: user.email,
                        user_name: `${user.last_name}, ${user.first_name}`,
                        team_id: user.team_id,
                        team_name: user.team.name,
                    });

                    $state.go('home.notActivated', { email: $scope.newUser.email });

                    $scope.isLoading = false;
                },
                (response) => {
                    $log.warn('Could not create new user');
                    $log.warn(response);
                    $scope.errors = response.data;
                    $scope.denied = true;
                    $scope.isLoading = false;
                    notification.error('Could not create user');
                },
            );
        };
    },
]);

export class SignupCtrl {
    constructor($scope, Messager, $state, $log, $window) {
        'ngInject';
    }
}

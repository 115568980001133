import { $log, Authenticator, Messager, $q, $state } from 'helioscope/app/utilities/ng';
import { downloadURL } from 'helioscope/app/utilities/io';
import * as pusher from 'helioscope/app/utilities/pusher';

import { Design } from 'helioscope/app/designer/Design';
import { downloadSLD } from 'helioscope/app/singleline/async/download_sld';


const mod = angular.module('helioscope.projects.controllers.designs', [
    'ngPDF',
]);


async function downloadFile(design, renderFunction, fileType) {
    const { channel, download_url: existingURL } = await renderFunction({ design_id: design.design_id }).$promise;

    if (existingURL != null) {
        downloadURL(existingURL);
    } else {
        const notification = Messager.load(`Creating ${fileType} File for ${design.description}`);

        try {
            const { download_url: url } = await pusher.promiseFromChannel(
                channel,
                ({ msg }) => notification.progress({ icon: 'fa fa-spinner fa-spin', text: msg }),
            );

            downloadURL(url);

            notification.success(
                `<a href="${url}" target="_blank">${fileType} Created Successfully</a>`,
                { delay: 5000 },
            );
        } catch (err) {
            notification.error(`Error generating ${fileType}`);
            $log.warn(err);
        }
    }
}

async function downloadDXF(design) {
    await downloadFile(design, Design.renderDXF, 'DXF');
}

async function downloadDAE(design) {
    await downloadFile(design, Design.renderDAE, 'DAE');
}

mod.controller('ProjectDesignCtrl', ($scope, Design) => { // eslint-disable-line no-shadow
    if ($scope.projectData.designs === undefined) {
        $scope.projectData.designs = Design.query({ project_id: $scope.project.project_id });
    }

    $scope.currentUser = Authenticator.user();
    const belongsToTeam = $scope.project.team_id === $scope.currentUser.team_id;
    $scope.canChangeLockStatus = $scope.currentUser.role.can_lock_design && belongsToTeam;

    $scope.changeLockStatus = (design) => {
        const failVerb = design.locked ? 'unlock' : 'lock';
        const successVerb = design.locked ? 'Unlocked' : 'Locked';

        if (!(design.creator_id === $scope.currentUser.user_id || $scope.canChangeLockStatus)) {
            Messager.error(`Only the creator of a design can ${failVerb} it`);
            return;
        }
        design.locked = !design.locked;
        design.$update().then(
            () => {
                Messager.info(`Successfully ${successVerb} Design`);
            },
            (err) => {
                $log.warn(err);
            },
        );
    };

    $scope.downloadSLD = downloadSLD;
    $scope.downloadDXF = downloadDXF;
    $scope.downloadDAE = downloadDAE;

    $scope.gotoDesign = (design) => {
        const deferred = $q.defer();
        $scope.$on('$stateChangeSuccess', () => deferred.resolve(`${design.description} loaded`));
        $scope.$on('$stateChangeError', () => deferred.reject(`Error loading ${design.description} loaded`));

        $state.go('designer.design', { design_id: design.design_id });
        return deferred.promise;
    };
});

mod.controller('ProjectDesignDetailModalCtrl', ['$scope', 'design', ($scope, design) => {
    $scope.design = design;
}]);

mod.controller('ProjectDesignDetailPrintCtrl', ['$scope', 'design', ($scope, design) => {
    $scope.design = design;
}]);

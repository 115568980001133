/* global angular:true */

import { default as moment } from 'moment';
import { userLocalDate } from 'helioscope/app/utilities/solar/solar_time';
import { Module, ModuleCharacterization, PowerDevice, AcConfig } from 'helioscope/app/libraries';
import { normalizeHsPower } from 'helioscope/app/utilities/helpers';

const mod = angular.module('helioscope.filters', []);

mod.filter('hsType', [function () {
    const types = {
        // racking type
        rack: 'Fixed Tilt',
        flush: 'Flush Mount',
        dual: 'East-West',
        carport: 'Carport',
        single_axis: 'Single-axis Trackers (N/S)',

        // stringing strategg
        along: 'Along Racking',
        updown: 'Up and Down Racking',

        // module orientation
        horizontal: 'Landscape (Horizontal)',
        vertical: 'Portrait (Vertical)',

        // transposition models
        hay: 'Hay Model',
        perez: 'Perez Model',

        // cell temp models
        sandia: 'Sandia Model',
        diffuse: 'Diffusion Model',

        // module models
        pvsyst: 'PAN',
        full_diode: 'Full-Diode',

        // module type
        monofacial: 'Monofacial',
        bifacial: 'Bifacial',
        mixed: 'Mixed',

        // cell technology name
        si_mono: 'Si-Mono',
        si_poly: 'Si-Poly',
        hit: 'HIT',
        cis: 'CIS',
        cdte: 'CdTe',
        ucsi_asi_h: 'uCSi-aSi:H',
        a_si_h_single: 'a-Si:H single',
        a_si_h_tandem: 'a-Si:H tandem',
        a_si_h_tripple: 'a-Si:H triple',

        // wire units
        awg: 'American Wire Gauge (AWG)',
        metric: 'Metric (mm2)',

        // tilt strategy
        fixed: 'Fixed Tilt Value',
        latitude: 'at Project Latitude',
        simple_optimization: 'Optimization for Project Latitude',

        // spectral adjustment method
        precipitable_water: 'First Solar Spectral Adjustment by Precipitable Water',
        relative_humidity: 'First Solar Spectral Adjustment by Relative Humidity',
        dew_point_temperature: 'First Solar Spectral Adjustment by Dew Point Temperature',

        solaredge: 'SolarEdge',

        initialized: 'awaiting payment',
        active: 'active',
        canceled: 'canceled',
        canceled_pending: 'canceled at end of current period',
        payment_fail: 'unable to charge the current payment method',
        past_due: 'past due',
        incomplete_expired: 'expired',
        incomplete: 'pending',


    };

    return function (val) {
        return types[val] || val;
    };
}]);

mod.filter('spacingStrategy', () => {
    // broken out because of a naming collision with tilt strategy (fixed)
    const types = {
        fixed: 'Fixed Row Spacing',
        span_to_rise: 'Fixed Span-to-Rise',
        gcr: 'Fixed Ground Coverage Ratio',
        shading: 'Shade Tolerance Window',
    };

    return function (val) {
        return types[val] || val;
    };
});

mod.filter('ceil', ['$filter', function ($filter) {
    const _number = $filter('number');

    return function (val) {
        return _number(Math.ceil(val));
    };
}]);

mod.filter('floor', ['$filter', function ($filter) {
    const _number = $filter('number');

    return function (val) {
        return _number(Math.floor(val));
    };
}]);

mod.filter('hsPower', ['$filter', function ($filter) {
    const _number = $filter('number');
    return function (watts, minSigFigs) {
        const { normalizedPower, unitsLabel, decimalPlaces } = normalizeHsPower(watts, minSigFigs);
        return ((_number(normalizedPower, decimalPlaces)) + ' ' + unitsLabel);

    }
}]);

mod.filter('delta', ['$filter', function ($filter) {
    const _number = $filter('number');

    return function (value) {
        return _number(100 * (value - 1), 1);
    };
}]);

mod.filter('chunk', [function () {
    return function (str, n) {
        if (!str) {
            return '';
        }

        n = (n || 10);
        const ret = [];
        const len = str.length;
        for (let i = 0; i < len; i += n) {
            ret.push(str.substr(i, n));
        }
        return ret.join('-');
    };
}]);

mod.filter('hsDistance', ['$filter', 'Authenticator', function ($filter, Authenticator) {
    const _number = $filter('number');
    const conversionMap = {
        ft: 3.28084,
        m: 1.0,
        mi: 0.00062137273,
        km: 0.001,
    };
    const unitMap = {
        ft: 'mi',
        m: 'km',
    };

    return function distanceFilter(val, precision, longDistance) {
        let unit = Authenticator.user().preferences.units.distance || 'm';
        if (longDistance === true) {
            unit = unitMap[unit];
        }

        distanceFilter.$unit = unit;
        return `${_number(val * conversionMap[unit], precision)} ${unit}`;
    };
}]);

mod.filter('hsArea', ['$filter', 'Authenticator', function ($filter, Authenticator) {
    const _number = $filter('number');
    const conversionMap = {
        ft: 3.28084 * 3.28084,
        m: 1.0,
        mi: 0.00062137273 * 0.00062137273,
        km: 0.001 * 0.001,
    };

    const unitMap = {
        ft: 'mi',
        m: 'km',
    };

    return function (val, precision, longDistance) {
        let unit = Authenticator.user().preferences.units.distance || 'm';
        if (longDistance === true) {
            unit = unitMap[unit];
        }

        return `${_number(val * conversionMap[unit], precision)} ${unit}`;
    };
}]);

mod.filter('momentFormat', () => (dt, format = 'YYYY-MM-DD') => dt && dt.format(format));

mod.filter('humanizedTimestamp', () =>
    // this should be preferred for reports that may be printed
     function humanizedTimestamp(val) {
         return moment(val).calendar();
     });

mod.filter('fromNow', () =>
    // this should be preferred for lists and other views
     function humanizedTimestamp(val) {
         return moment(val).fromNow();
     });

mod.filter('capitalize', () =>
    function capitalize(input) {
        if (input) {
            return input[0].toUpperCase() + input.slice(1);
        }
        return input;
    });

mod.filter('degrees', ($filter) => {
    const _number = $filter('number');
    return function addDegreeSymbol(val, precision) {
        return `${_number(val, precision)}º`;
    };
});

mod.filter('localDate', ($filter) => {
    const dateFilter = $filter('date');

    return function (date, format) {
        return dateFilter(userLocalDate(date), format);
    };
});

mod.filter('moduleName', () => (id) => (Module.cached(id) || `${id}`).toString());
mod.filter('moduleCharacterizationName', ($filter) => (id) => {
    const c = ModuleCharacterization.cached(id);
    const hsTypeFilter = $filter('hsType');
    return (
        (c.module_characterization_id === c.module.user_default_characterization_id ? '(user default) ' : '') +
        (c.module_characterization_id === c.module.default_characterization_id ? '(system default) ' : '') +
        c.name +
        ', ' +
        hsTypeFilter(c.module_model_name)
    );
});

mod.filter('deviceName', () => (id) => PowerDevice.cached(id) || `${id}`);

mod.filter('acConfigName', () => (id) => AcConfig.cached(id) || `${id}`);

/*global angular:true*/
import moment from 'moment';
import { sortBy } from 'lodash';
import { getP90ConfigURL, getBifacialCondSetEditURL } from 'helioscope/app/utilities/url';
import DismissibleManager, { DismissibleItem } from 'reports/utils/DismissibleManager';
import { $rootScope } from '../utilities/ng';

(function (angular) {
    'use strict';
    var mod = angular.module('helioscope.projects.controllers.scenarios', ['helioscope.services', 'ui', 'helioscope.projects.resources']);

    mod.controller('ProjectScenarioCtrl', ['$scope', 'Scenario', function ($scope, Scenario) {
        if ($scope.projectData.scenarios === undefined) {
            $scope.projectData.scenarios = Scenario.query({project_id: $scope.project.project_id});
        }

        const p90DismissableManager = new DismissibleManager($scope.user().user_id, DismissibleItem.P90_CLASSIC_CALLOUT);
        const bifacialDismissableManager = new DismissibleManager($scope.user().user_id, DismissibleItem.BIFACIAL_CLASSIC_CALLOUT);

        $scope.showP90Callout = $rootScope.user().hasP90WeatherVariabilityAccess() && !p90DismissableManager.isDismissed();

        $scope.dismissP90Callout = () => {
            p90DismissableManager.dismiss();
            $scope.showP90Callout = false;
        }

        $scope.getP90ConfigURL = () => {
            if($scope.projectData.scenarios.length > 0) {
                const lastCreatedScenarioId = $scope.projectData.scenarios.at(-1).scenario_id;
                return getP90ConfigURL($scope.project.project_id, lastCreatedScenarioId);
            }
        };

        $scope.showBifacialCallout = $rootScope.user().hasFeature('enable_bifacial') && !bifacialDismissableManager.isDismissed();

        $scope.dismissBifacialCallout = () => {
            bifacialDismissableManager.dismiss();
            $scope.showBifacialCallout = false;
        }
        $scope.getBifacialConfigURL = () => {
            return getBifacialCondSetEditURL($scope.project.project_id, $scope.projectData.scenarios)
        };
    }]);

    mod.controller('ProjectScenarioDetailCtrl', ['$scope', 'scenario', '$modalInstance',
                                                 function ($scope, scenario, $modalInstance) {
            $scope.scenario = scenario;

            $scope.close = function () {
                $modalInstance.close();
            };
        }]);
}(angular));

/* eslint no-unused-vars: 0 */
import _ from 'lodash';

import { $q } from 'helioscope/app/utilities/ng';

import { FLError } from 'helioscope/app/utilities/helpers';

import { RelationalBase, relationship, deserializeObject } from 'helioscope/app/relational';
import { Vector } from 'helioscope/app/utilities/geometry';

import { Project } from 'helioscope/app/projects/resources';
import * as statsd from 'helioscope/app/utilities/statsd';
import { SOURCES, DISPLAY_MODES } from 'helioscope/app/apollo/LidarHelper';

import { DesignScene } from './design_manager/DesignScene';
import { FieldSegment } from './field_segment/FieldSegment';
import { WiringZone } from './wiring_zone';
import { generateFullComponentTree, flattenComponentTree, Interconnect, DEFAULT_AC_CONFIG } from './components';

class DesignError extends FLError {}

const componentCache = new WeakMap();

export class Design extends RelationalBase {
    static relationName = 'Design';

    initializeDesignScene() {
        this.$$designScene = new DesignScene(this); // use $$ so that angular.copy skips over it
        this.$$designScene.initializeGeometry();
        return this.$$designScene;
    }

    designScene() {
        if (this.$$designScene === undefined) {
            throw new DesignError('design scene not initialized yet');
        }

        return this.$$designScene;
    }

    restore() {
        this.to_delete = false;
        return this.$update();
    }

    acConfig() {
        if (this.ac_config_id) {
            return this.ac_config;
        }

        if (this.project.ac_config_id) {
            return this.project.ac_config;
        }

        return DEFAULT_AC_CONFIG;
    }

    defaultPccLocation() {
        if (this.geometry && this.geometry.pcc_location) {
            return this.geometry.pcc_location;
        }

        const project = this.project;

        if (project.geometry && project.geometry.pcc_location) {
            return project.geometry.pcc_location;
        }

        return new Vector(0, 0);
    }

    fieldSegment(fieldSegmentId) {
        const fieldSegment = FieldSegment.cached(fieldSegmentId);
        return fieldSegment && fieldSegment.design_id === this.design_id ? fieldSegment : undefined;
    }

    wiringZone(wiringZoneId) {
        const wiringZone = WiringZone.cached(wiringZoneId);
        return wiringZone && wiringZone.design_id === this.design_id ? wiringZone : undefined;
    }

    @statsd.instrument('loadFieldComponents')
    loadFieldComponents(rawComponents) {
        const wiringMap = _.groupBy(rawComponents, 'wiring_zone_id');

        const allComponents = [];
        const allUnmatchedModules = [];
        _.forEach(wiringMap, (fieldData, wiringZoneId) => {
            const wiringZone = this.wiringZone(wiringZoneId);
            const { components, unmatchedModules } = wiringZone.loadFieldComponents(fieldData);

            allComponents.push(...components);
            allUnmatchedModules.push(...unmatchedModules);
        });

        if (this.hasInterconnect()) {
            const interconnect = Interconnect.create(this.geometry.pcc_location, allComponents);

            componentCache.set(this, [interconnect]);
        } else {
            componentCache.set(this, allComponents);
        }

        return { components: componentCache.get(this), unmatchedModules: allUnmatchedModules };
    }

    @statsd.instrument('generateWiring')
    generateWiring() {
        const components = generateFullComponentTree(this);

        // clear server generated cache of wiring metadata
        // TODO: make this client side generated
        delete this.field_component_metadata;

        componentCache.set(this, components);

        return components;
    }

    getComponents() {
        return componentCache.get(this);
    }

    static ensureInterconnect(components) {
        const first = _.first(components);
        if (!first) {
            return null;
        }
        if (first instanceof Interconnect) {
            return first;
        }

        return new Interconnect({ children: components });
    }

    getFlattenedComponents(typesToInclude) {
        return flattenComponentTree(componentCache.get(this), typesToInclude);
    }

    getFieldModuleMap() {
        return _.keyBy(this.getFlattenedComponents({ module: true }), 'field_component_id');
    }

    hasInterconnect() {
        return this.geometry && Boolean(this.geometry.pcc_location) === true;
    }

    supportsSingleline() {
        const components = this.getComponents();

        // will have components if in the designer, if the components haven't been loaded you know
        // they're available (pending a server call) if field_component_metadata is populated
        return (components && components.length > 0) || this.nameplate;
    }

    getWiredModules() {
        return this.getFlattenedComponents({ module: true });
    }

    getUnwiredModules() {
        const unwired = this.wiring_zones.reduce((res, wiringZone) => {
            const modules = Object.values(wiringZone.getUnwiredModules()).flat();
            return res.concat(modules);
        }, []);

        return unwired;
    }

    hasComponents() {
        return _.has(this, 'field_component_metadata.nameplate');
    }

    get nameplate() {
        return _.get(this, 'field_component_metadata.nameplate');
    }

    canUserChangeLockedState(user) {
        const isCreator = this.creator_id === user.user_id;
        // for locked designs, allow creator of the project to unlock it,
        // regardless of team status
        if (this.locked && isCreator) {
            return true;
        }
        const belongsToTeam = this.project.team_id === user.team_id;
        const canChangeLockStatus = user.role.can_lock_design && belongsToTeam;
        return this.project.team_id && (isCreator || canChangeLockStatus);
    }

    toString() {
        return this.description;
    }

    physicalSurfaces() {
        return this.field_segments.concat(this.keepouts);
    }

    wiringZoomPath() {
        const components = this.getComponents();

        return flattenComponentTree(components, {
            inverter: true,
            interconnect: true,
            ac_panel: true,
            combiner: true,
        }).map((c) => c.location);
    }

    zoomPath(includeWiring = true) {
        const raw = _.flatten([
            ...this.physicalSurfaces().map((ps) => ps.zoomPath()),
            ...this.entity_premades.map((pm) => pm.zoomPath()),
            ...(includeWiring ? this.wiringZoomPath() : []),
        ]);

        return _.filter(raw, (i) => !isNaN(i.x) && !isNaN(i.y));
    }

    async loadDependencies() {
        if (_.isEmpty(this.wiring_zones)) {
            await Design.get({ design_id: this.design_id }).$promise;
        }

        await $q.all([
            ...this.field_segments.map((fieldSegment) => fieldSegment.loadDependencies()),
            ...this.wiring_zones.map((wiringZone) => wiringZone.loadDependencies()),
        ]);

        return this;
    }

    get lidarSettings() {
        // Designs created before the required lidar_settings fields were added
        // need them to be initialized to the defaults
        if (!this.geometry.lidar_settings || !this.geometry.lidar_settings.data_source) {
            this.geometry.lidar_settings = {
                data_source: SOURCES.SUNROOF,
                display_mode: DISPLAY_MODES.TRIANGLES,
            };
        }

        return this.geometry.lidar_settings;
    }
}

class DesignGeometry extends RelationalBase {
    static relationName = 'DesignGeometry';
}

Design.configureRelationships({
    project: relationship(Project, { backref: 'designs' }),
    ac_config: relationship('AcConfig'),
    geometry: deserializeObject(DesignGeometry),
});

DesignGeometry.configureRelationships({
    pcc_location: deserializeObject(Vector),
    backup_design: relationship(Design),
});

Design.createEndpoint(
    '/api/designs/:design_id',
    { design_id: '@design_id' },
    {
        update: { method: 'PUT', isArray: false },
        renderDXF: { method: 'GET', isArray: false, url: '/api/designs/:design_id/render_dxf' },
        renderDAE: { method: 'GET', isArray: false, url: '/api/designs/:design_id/render_dae' },
        render: { method: 'PUT', isArray: false, url: '/api/designs/:design_id/trigger-render' },
    },
);

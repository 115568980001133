export enum DismissibleItem {
    P90_CLASSIC_CALLOUT = 'P90_CLASSIC_CALLOUT',
    TEST_DISMISSIBLE_ITEM = 'TEST_DISMISSIBLE_ITEM',
    BIFACIAL_CLASSIC_CALLOUT = 'BIFACIAL_CLASSIC_CALLOUT',
    NEW_DESIGN_BIFACIAL_CALLOUT = 'NEW_DESIGN_BIFACIAL_CALLOUT',
    EXISTING_DESIGN_BIFACIAL_CALLOUT = 'EXISTING_DESIGN_BIFACIAL_CALLOUT',
}

const DISMISSED_ITEMS_LOCAL_STORAGE_KEY = 'dismissedItems';

export default class DismissibleManager {
    private item: DismissibleItem;
    private userId: string;

    constructor(userId: string, item: DismissibleItem) {
        this.item = item;
        this.userId = userId;
    }

    private getLocalStorageKey(): string {
        return `${DISMISSED_ITEMS_LOCAL_STORAGE_KEY}_${this.userId}`;
    }

    private getDismissibleItemsSet(): Set<DismissibleItem> {
        const items = localStorage.getItem(this.getLocalStorageKey());
        return items ? new Set(JSON.parse(items)) : new Set();
    }

    private updateDismissibleItemsSet(items: Set<DismissibleItem>): void {
        localStorage.setItem(this.getLocalStorageKey(), JSON.stringify(Array.from(items)));
    }

    public isDismissed(): boolean {
        const dismissedItems = this.getDismissibleItemsSet();
        return !!dismissedItems.has(this.item);
    }

    public dismiss(): void {
        if (this.isDismissed()) {
            return;
        }

        const dismissedItems = this.getDismissibleItemsSet();
        dismissedItems.add(this.item);
        this.updateDismissibleItemsSet(dismissedItems);
    }

    public restore(): void {
        if (this.isDismissed()) {
            const dismissedItems = this.getDismissibleItemsSet();
            dismissedItems.delete(this.item);
            this.updateDismissibleItemsSet(dismissedItems);
        }
    }
}

import { $modal } from 'helioscope/app/utilities/ng';
import { ModuleCharacterization } from 'helioscope/app/libraries/resources';
import { StateDelta } from './persistence/deltas';

export function animateOnChange($animate, $timeout) {
    'ngInject';

    return {
        restrict: 'A',
        require: '^ngModel',
        link: (scope, element, attrs, ngModelController) => {
            const animationClass = attrs.animateOnChange;
            let skipFirst = true;
            ngModelController.$formatters.push((val) => {
                if (skipFirst) {
                    skipFirst = false;
                } else {
                    $animate.addClass(element, animationClass).then(
                        // in theory should be able to do this with a regular setTimoeut, but sometimes
                        // the animations get interrupted (I think due to other directives like
                        // ng-show/hide/if), using the builting fixes is (bc of the digest)
                        () => $timeout(() => $animate.removeClass(element, animationClass)),
                    );
                }

                return val;
            });
        },
    };
}

export function updateModuleCharacterizations() {
    'ngInject';

    return {
        restrict: 'EA',
        scope: { design: '=', stateHandler: '=', moduleCharacterizations: '=' },
        templateUrl: require('helioscope/app/designer/partials/directives.update_module_characterizations.html'),
        link: ($scope) => {
            $scope.objectKeys = Object.keys;
            const setId = async (moduleId, val, addDelta = true) => {
                const fieldSegments = $scope.design.field_segments.filter((fs) => fs.module_id === moduleId);
                if (fieldSegments.length === 0) {
                    return $scope.design;
                }
                const oldVal = fieldSegments[0].module_characterization_id;

                const newMc = ModuleCharacterization.cached(val);
                const oldMc = ModuleCharacterization.cached(oldVal);
                if (newMc.length !== oldMc.length || newMc.width !== oldMc.width || newMc.power !== oldMc.power) {
                    const modalInstance = $modal.open({
                        templateUrl: require('helioscope/app/designer/partials/designer.confirm.characterization.modal.html'),
                        size: 'md',
                        controller() {
                            this.confirm = () => modalInstance.close(true);
                        },
                        controllerAs: 'ctrl',
                    });
                    try {
                        await modalInstance.result;
                    } catch (e) {
                        return $scope.design;
                    }
                }

                fieldSegments.forEach((fieldSegment) => {
                    fieldSegment.module_characterization_id = val;
                    $scope.stateHandler.getCallback(fieldSegment)(
                        // re-render field segment in case dims changed
                        fieldSegment,
                        'module_characterization_id',
                        val,
                        oldVal,
                    );
                    $scope.stateHandler.updateQueue.schedule(fieldSegment);
                });
                if (addDelta) {
                    const name = 'Module Characterization ID';
                    const delta = new StateDelta({
                        resource: $scope.design,
                        loadText: `Redo ${name} from: ${oldVal} to ${val}`,
                        loadFn: () => setId(moduleId, val, false),
                        rollbackText: `Undo ${name} from: ${val} to ${oldVal}`,
                        rollbackFn: () => setId(moduleId, oldVal, false),
                    });
                    delta.isPropertyDelta = true;
                    $scope.stateHandler.addDelta(delta);
                }
                return $scope.design;
            };
            const gs = (moduleId, val) => {
                if (val) {
                    setId(moduleId, val);
                } else {
                    $scope.design.field_segments.forEach((fieldSegment) => {
                        if (fieldSegment.module_id === moduleId) {
                            val = fieldSegment.module_characterization_id;
                        }
                    });
                }
                return val;
            };
            $scope.getterSetter = (moduleId) => (val) => gs(Number(moduleId), val);
        },
    };
}

/**
 * component to allow changing the acConfig for a design
 */
export function updateAcConfig() {
    'ngInject';

    return {
        restrict: 'EA',
        scope: { design: '=', stateHandler: '=', acConfigs: '=' },
        templateUrl: require('helioscope/app/designer/partials/directives.update_ac_config.html'),
    };
}

export function designerCallout() {
    'ngInject';

    return {
        restrict: 'E',
        templateUrl: require('helioscope/app/designer/field_segment/partials/callout.html'),
        scope: { isVisible: '=', calloutDescription: '@', calloutHeader: '@', dismiss: '=' },
        controller($scope, $sce) {
            // The callout description supports raw html, so we must trust the string values as html to properly render it
            // This needs to be reassigned to another variable in scope, because if you modify calloutDescription/calloutHeader
            // in place, it triggers the scope watch and causes a digest attempt on top of the previous one
            $scope.$watch('calloutDescription', () => {
                $scope.descriptionText = $sce.trustAsHtml($scope.calloutDescription);
            });
            $scope.$watch('calloutHeader', () => {
                $scope.headerText = $sce.trustAsHtml($scope.calloutHeader);
            });
        },
    };
}
